import { getFunctions, httpsCallable } from 'firebase/functions';

// Initialize Firebase Functions
const functions = getFunctions();

export const sendEmail = async (bookingData, adminEmail) => {
  try {
    // Call the Firebase Cloud Function
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');
    const response = await sendEmailFunction({ bookingData, adminEmail });
    
    // Handle success or error
    if (response.data.success) {
      console.log('Email sent successfully:'/*, response.data*/);
    } else {
      console.error('Failed to send email:'/*, response.data.error*/);
    }
  } catch (error) {
    console.error('Error sending email:'/*, error*/);
  }
};

export const sendEmailContactForm = async (contactForm, adminEmail) => {
  try {
    // Call the Firebase Cloud Function
    const sendEmailContactFormFunction = httpsCallable(functions, 'sendEmailContactForm');
    const response = await sendEmailContactFormFunction({ contactForm, adminEmail });
    
    // Handle success or error
    if (response.data.success) {
      console.log('Contact email sent successfully:'/*, response.data*/);
    } else {
      console.error('Failed to send contact email:'/*, response.data.error*/);
    }
  } catch (error) {
    console.error('Error sending contact email:'/*, error*/);
  }
};

export async function getAdminEmail() {
    // const adminsCollection = collection(db, 'users');
    // const adminsQuery = query(adminsCollection, where('isAdmin', '==', true));
    // const adminsSnapshot = await getDocs(adminsQuery);

    // if (!adminsSnapshot.empty) {
    //   // Assuming there's only one admin with isAdmin set to true
    //   const adminData = adminsSnapshot.docs[0].data();
    //   return adminData.email;
    // }

    return 'info@luxtransport.ca'
  };
