import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, deleteDoc, doc, updateDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db, auth, signInWithGoogle, storage } from '../firebase';
import { ref, uploadBytes, deleteObject, listAll, getDownloadURL } from 'firebase/storage';
import { Button, Modal, Alert, Form, FormCheck } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

const Admin = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [editBookingData, setEditBookingData] = useState(null);
  const [allowedUser, setAllowedUser] = useState(null);
  const navigate = useNavigate();

  const [contactForms, setContactForms] = useState([]);
  const [loadingForms, setLoadingForms] = useState(true);
  const [showContactFormModal, setShowContactFormModal] = useState(false);
  const [selectedContactFormForRemoval, setSelectedContactFormForRemoval] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  const [promoCode, setPromoCode] = useState('');
  const [promoPercentage, setPromoPercentage] = useState('');
  const [promoMaxDiscountAmount, setPromoMaxDiscountAmount] = useState('');
  const [promoMinPaymentAmount, setPromoMinPaymentAmount] = useState('');
  const [promoUntilDate, setPromoUntilDate] = useState('');
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [uploadStatusPromoCode, setUploadStatusPromoCode] = useState(null);
  const [promoIsActive, setPromoIsActive] = useState(false);

  const deactivatePromoCode = async () => {
    try {
      const promoDocRef = doc(db, 'promoCodes', 'currentPromo');
      const promoData = {
        isActive: false, 
      };
      await updateDoc(promoDocRef, promoData); 
      setPromoIsActive(false); 
      setShowPromoModal(true);
      //console.log('Promo code deactivated successfully!');
    } catch (error) {
      //console.error('Error deactivating promo code:', error);
    }
  };

  const savePromoCodeToDatabase = async () => {
    try {
      const promoDocRef = doc(db, 'promoCodes', 'currentPromo');
      const promoData = {
        code: promoCode,
        percentage: parseInt(promoPercentage),
        maxDiscountAmount: parseInt(promoMaxDiscountAmount),
        untilDate: Timestamp.fromDate(new Date(promoUntilDate)),
        minPaymentAmount: parseInt(promoMinPaymentAmount),
        isActive: true,
      };
      await setDoc(promoDocRef, promoData);
      setPromoIsActive(true); 
      setShowPromoModal(true);
      //console.log('Promo code saved successfully!');
    } catch (error) {
      //console.error('Error saving promo code:', error);
      setUploadStatusPromoCode({ type: 'error', message: 'Error saving promo code' });
    }
  };

  const [carSegments, setCarSegments] = useState([]);
  const [newSegment, setNewSegment] = useState({
    title: '',
    people: 0,
    suitcases: 0,
    imageUrl: '',
    isActive: false,
  });

  // Function to add a new car segment
  const addCarSegment = async () => {
    try {
      // Add the new segment to Firestore
      const segmentCollection = collection(db, 'carSegments');
      await setDoc(doc(segmentCollection), newSegment);
      
      // Fetch updated car segments
      fetchCarSegments();

      // Reset the newSegment state
      setNewSegment({
        title: '',
        people: 0,
        suitcases: 0,
        imageUrl: '',
        isActive: false,
      });
    } catch (error) {
      //console.error('Error adding car segment:', error);
    }
  };

  // Function to toggle the isActive property of a car segment
  const toggleSegmentActivation = async (segmentId, isActive) => {
    try {
      // Update the isActive property of the segment in Firestore
      await updateDoc(doc(db, 'carSegments', segmentId), { isActive: !isActive });
      
      // Fetch updated car segments
      fetchCarSegments();
    } catch (error) {
      //console.error('Error toggling segment activation:', error);
    }
  };

  // Function to fetch car segments from Firestore
  const fetchCarSegments = async () => {
    try {
      const segmentsCollection = collection(db, 'carSegments');
      const querySnapshot = await getDocs(segmentsCollection);
      const segmentsData = [];
      querySnapshot.forEach((doc) => {
        segmentsData.push({ id: doc.id, ...doc.data() });
      });
      setCarSegments(segmentsData);
    } catch (error) {
      //console.error('Error fetching car segments:', error);
    }
  };

  const handleUpload = async (file) => {
  if (!file) {
    setUploadStatus({ type: 'error', message: 'No file selected for upload' });
    return;
  }

  try {
    // Upload the new file
    const fileRef = ref(storage, file.name);
    await uploadBytes(fileRef, file);

    // Inform the user about successful upload
    setUploadStatus({ type: 'success', message: `${file.name} uploaded successfully` });

    // Clear the selected file after upload
    setSelectedFile(null);

  } catch (uploadError) {
    //console.error('Error uploading file:', uploadError);
    setUploadStatus({ type: 'error', message: 'Error uploading file' });
  }
};

const [fileList, setFileList] = useState([]);

  const fetchFileList = async () => {
    try {
      const storageRef = ref(storage);
      const files = await listAll(storageRef);
      const filePromises = files.items.map(async (fileRef) => {
        const url = await getDownloadURL(fileRef);
        return { name: fileRef.name, url };
      });
      const resolvedFiles = await Promise.all(filePromises);
      setFileList(resolvedFiles);
    } catch (error) {
      //console.error('Error fetching file list:', error);
    }
  };

  //for handling status of booking
  const statusOptions = {
    completed: { text: 'Completed', color: 'success' },
    scheduled: { text: 'Scheduled', color: 'info' },
    pending: { text: 'Pending', color: 'secondary' },
  };

  const [showStatusModal, setShowStatusModal] = useState(false);

  const handleStatusButtonClick = (row) => {
    setSelectedBooking(row);
    setShowStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setShowStatusModal(false);
    setSelectedBooking(null);
  };

  const handleConfirmStatusChange = async (newStatus) => {
    if (selectedBooking) {
      try {
        const bookingRef = doc(db, 'bookings', selectedBooking.id);

        // Update Firestore
        await updateDoc(bookingRef, {
          status: newStatus,
        });

        // Update local state (bookings) to reflect the change immediately
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.id === selectedBooking.id ? { ...booking, status: newStatus } : booking
          )
        );

        // Close the modal
        handleCloseStatusModal();
      } catch (error) {
        //console.error('Error updating document: ', error);
      }
    }
  };



  const handleEditBooking = (booking) => {
    setSelectedBooking(booking);
    setEditBookingData({ ...booking }); // Populate editBookingData with selected booking
    setShowEditModal(true);
  };

  const handleSaveEdit = async () => {
    try {
      const updatedData = {
        driverEmail: editBookingData.driverEmail
      };
      // Update the booking in Firestore with the edited data
      await updateDoc(doc(db, 'bookings', selectedBooking.id), updatedData);
      // Refresh the bookings list after updating the booking
      fetchBookings();
      setShowEditModal(false);
    } catch (error) {
      //console.error('Error updating booking:', error);
    }
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
    setEditBookingData(null); // Clear editBookingData
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const handleRemove = async () => {
    try {
      await deleteDoc(doc(db, 'bookings', selectedBooking.id));
      setShowModal(false);
      // Refresh the bookings list after removal
      fetchBookings();
    } catch (error) {
      //console.error('Error removing booking:', error);
    }
  };

  const handleRemoveContactForm = (selectedContactForm) => {
    setSelectedContactFormForRemoval(selectedContactForm);
    setShowContactFormModal(true);
  };

  const handleConfirmRemoveContactForm = async () => {
    try {
      await deleteDoc(doc(db, 'contactForms', selectedContactFormForRemoval.id));
      // Refresh the contactForms list after removal
      fetchContactForms();
      setShowContactFormModal(false);
    } catch (error) {
      //console.error('Error removing contact form:', error);
    }
  };

  const handleCancelRemoveContactForm = () => {
    setSelectedContactFormForRemoval(null);
    setShowContactFormModal(false);
  };

  const fetchBookings = async () => {
    // Only proceed if the allowedUser state is set
    if (!allowedUser) {
      return;
    }

    const bookingsCollection = collection(db, 'bookings');

    try {
      const querySnapshot = await getDocs(bookingsCollection);
      const bookingData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.pickupDate = data.pickupDate ? data.pickupDate.toDate().toLocaleDateString() : 'N/A';
        data.returnDate = data.returnDate ? data.returnDate.toDate().toLocaleDateString() : 'N/A';
        data.bookingDate = data.bookingDate ? data.bookingDate.toDate().toLocaleDateString() : 'N/A';

        // Set default status if it doesn't exist
        data.status = data.status || 'pending';

        bookingData.push({ id: doc.id, ...data });
      });
      setBookings(bookingData);
      setLoading(false);
    } catch (error) {
      //console.error('Error fetching booking details:', error);
      setLoading(false);
    }
  };

  const fetchContactForms = async () => {
    try {
      const contactFormsCollection = collection(db, 'contactForms');
      const querySnapshot = await getDocs(contactFormsCollection);
      const contactFormData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.timestamp = data.timestamp ? data.timestamp.toDate().toLocaleDateString() : 'N/A';
        contactFormData.push({ id: doc.id, ...data });
      });
      setContactForms(contactFormData);
      setLoadingForms(false);
    } catch (error) {
      //console.error('Error fetching contact forms:', error);
      setLoadingForms(false);
    }
  };

  useEffect(() => {
    fetchCarSegments();
    fetchFileList();
    fetchContactForms();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Check if the user's email is allowed
        checkUserEmail(user.email);
      } else {
        // If the user is not signed in, redirect to the login page
        navigate('/login');
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
  // Fetch bookings only if the user's email is allowed
  if (allowedUser) {
    fetchBookings();
  }
}, [allowedUser]);

  useEffect(() => {
  // Add event listener for beforeunload
  const handleBeforeUnload = async () => {
    // Perform sign-out or any other cleanup logic
    await auth.signOut();
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);

  const checkUserEmail = async (email) => {
    const allowedEmailsCollection = collection(db, 'users');
    const querySnapshot = await getDocs(allowedEmailsCollection);
    const allowedUsers = querySnapshot.docs.map((doc) => doc.data());

    const user = allowedUsers.find((user) => user.email === email && user.isAdmin);

    if (!user) {
      navigate('/login');
      return;
    } else {
      setAllowedUser(user); // Set the allowed user
      // Fetch bookings only if the user's email is allowed
      //fetchBookings();
    }
  };

  const contactFormColumns = [
    { dataField: 'name', text: 'Name', sort: true, filter: textFilter() },
    { dataField: 'email', text: 'Email', sort: true, filter: textFilter() },
    { dataField: 'message', text: 'Message', sort: true, filter: textFilter() },
    { dataField: 'timestamp', text: 'Timestamp', sort: true, filter: textFilter() },
    {
      dataField: 'removeButton',
      text: 'Remove',
      formatter: (_, row) => (
        <Button variant="danger" onClick={() => handleRemoveContactForm(row)}>
          Remove
        </Button>
      ),
    },
  ];

  const columns = [
    { dataField: 'orderNumber', text: 'Order Number', sort: true, filter: textFilter() },
    { dataField: 'pickupDate', text: 'Pickup Date', sort: true, filter: textFilter() },
    { dataField: 'pickupLocation', text: 'Pickup Location', sort: true, filter: textFilter() },
    { dataField: 'dropOffLocation', text: 'Drop-off Location', sort: true, filter: textFilter() },
    { dataField: 'selectedVehicle', text: 'Selected Vehicle', sort: true, filter: textFilter() },

    { dataField: 'returnDate', text: 'Return Pickup Date', sort: true, filter: textFilter() },
    { dataField: 'returnPickupLocation', text: 'Return Pickup Location', sort: true, filter: textFilter() },
    { dataField: 'returnDropOffLocation', text: 'Return Drop-off Location', sort: true, filter: textFilter() },
    { dataField: 'returnSelectedVehicle', text: 'Return Selected Vehicle', sort: true, filter: textFilter() },

    { dataField: 'selectedPaymentMethod', text: 'Payment Method', sort: true, filter: textFilter() },
    { dataField: 'totalPrice', text: 'Price', sort: true, filter: textFilter() },
    { dataField: 'userEmail', text: 'User Email', sort: true, filter: textFilter() },
    { dataField: 'userPhone', text: 'User Phone', sort: true, filter: textFilter() },

    { dataField: 'bookingDate', text: 'Booking Date', sort: true, filter: textFilter() },

    { dataField: 'driverEmail', text: 'Driver Email', sort: true, filter: textFilter() },

    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (_, row) => (
        <div>
          {/* Edit button */}
          <Button variant="info" onClick={() => handleEditBooking(row)}>
            Edit
          </Button>
          {/* Remove button */}
          <Button variant="danger" onClick={() => handleShow(row)}>
            Remove
          </Button>
        </div>
      ),
    },

    {
      dataField: 'status',
      text: 'Status',
      formatter: (_, row) => (
        <Button
          variant={statusOptions[row.status]?.color}
          onClick={() => handleStatusButtonClick(row)}
        >
          {statusOptions[row.status]?.text}
        </Button>
      ),
      sort: true,
    },

    // ... (other columns)


  ];

  return (
    <div>
      <div style={{ textAlign: 'center', margin: '20px' }}>
        <h1>Admin Page</h1>
      </div>

      <div>
        <h3>Links</h3>
        <ul>
          <li>
            <a href="https://console.firebase.google.com/u/1/project/airporttripbooking/overview" target="_blank" rel="noopener noreferrer">
              Firebase
            </a> - Used for Database and Hosting services.
          </li>
          <li>
            <a href="https://sso.godaddy.com/?realm=idp&app=venture-redirector&path=%2F" target="_blank" rel="noopener noreferrer">
              GoDaddy
            </a> - Used for Domain service.
          </li>
          <li>
            <a href="https://www.paypal.com/signin" target="_blank" rel="noopener noreferrer">
              Paypal
            </a> - Used for payment transactions.
          </li>
          <li>
            <a href="https://outlook.office.com/mail/" target="_blank" rel="noopener noreferrer">
              Outlook (info@luxtransport.ca)
            </a> - Used for emails.
          </li>
          <li>
            <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank" rel="noopener noreferrer">
              Gmail (luxtransportca@gmail.com)
            </a> - Used for forwarding bookings to drivers.
          </li>
          <li>
            <a href="https://app.mailersend.com/activity" target="_blank" rel="noopener noreferrer">
              MailerSend
            </a> - Used for sending emails of bookings.
          </li>
        </ul>
      </div>

      <h3>Bookings</h3>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <BootstrapTable
          keyField="id"
          data={bookings}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory()}
          filterPosition="top"
        />
      )}

      {/* Contact Forms Table */}
      <h3>Contact Forms</h3>
      {loadingForms ? (
        <p>Loading contact forms...</p>
      ) : (
        <BootstrapTable
          keyField="id"
          data={contactForms}
          columns={contactFormColumns}
          filter={filterFactory()}
          pagination={paginationFactory()}
          filterPosition="top"
        />
      )}

      {/* Edit Booking Modal */}
      <Modal show={showEditModal} onHide={handleCancelEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Input fields for editing booking data */}
          <Form>
            <Form.Group controlId="editDriverEmail">
              <Form.Label>Driver Email</Form.Label>
              <Form.Control
                type="text"
                value={editBookingData?.driverEmail}
                onChange={(e) => setEditBookingData({ ...editBookingData, driverEmail: e.target.value })}
              />
            </Form.Group>
            {/* Other input fields for editing... */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelEdit}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Remove confirmation modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this booking? 
          <br></br>-Order <b>{selectedBooking?.orderNumber}</b>
          <br></br>-Email <b>{selectedBooking?.userEmail}</b>
          <br></br>-Departure from <b>{selectedBooking?.pickupLocation}</b> to <b>{selectedBooking?.dropOffLocation}</b> on <b>{selectedBooking?.pickupDate}</b>
           {selectedBooking?.returnPickupLocation && 
           <>
            <br></br>-Return from<b>{selectedBooking?.returnPickupLocation}</b> to <b>{selectedBooking?.returnDropOffLocation}</b> on <b>{selectedBooking?.returnDate}</b>
            </>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRemove}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Remove confirmation modal for contactForms */}
      <Modal show={showContactFormModal} onHide={handleCancelRemoveContactForm}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Contact Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this contact form?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelRemoveContactForm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmRemoveContactForm}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>

      {/*Modal for confirming status change*/}
      <Modal show={showStatusModal} onHide={handleCloseStatusModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Booking Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to update the status of this booking?
          <br />
          - Order <b>{selectedBooking?.orderNumber}</b>
          <br />
          - Email <b>{selectedBooking?.userEmail}</b>
          <br />
          - Departure from <b>{selectedBooking?.pickupLocation}</b> to <b>{selectedBooking?.dropOffLocation}</b> on
          <b>{selectedBooking?.pickupDate}</b>
          {selectedBooking?.returnPickupLocation && (
            <>
              <br />
              - Return from <b>{selectedBooking?.returnPickupLocation}</b> to <b>{selectedBooking?.returnDropOffLocation}</b>
              on <b>{selectedBooking?.returnDate}</b>
            </>
          )}
          <br />
          - Current Status <b>{statusOptions[selectedBooking?.status]?.text}</b>
          <br />
          <br />
          <p>Select new status:</p>
          <Button variant="success" onClick={() => handleConfirmStatusChange('completed')}>
            Completed
          </Button>
          <Button variant="info" onClick={() => handleConfirmStatusChange('scheduled')}>
            Scheduled
          </Button>
          <Button variant="secondary" onClick={() => handleConfirmStatusChange('pending')}>
            Pending
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStatusModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <h3>Update Prices & Images</h3>
        <h5>Files in Storage:</h5>
        <ul>
          {fileList.map((file) => (
            <li key={file.name}>
              <a href={file.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>{file.name}</a>
            </li>
          ))}
        </ul>
        <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
        <Button variant="primary" onClick={() => handleUpload(selectedFile)}>
          Upload New File
        </Button>

        {uploadStatus && (
          <Alert variant={uploadStatus.type} className="mt-3">
            {uploadStatus.message}
          </Alert>
        )}
      </div>



        <div>
        {/* Input fields for promo code and additional properties */}
        <h3>Update Promo Code</h3>
        <input
          type="text"
          placeholder="Promo Code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <input
          type="number"
          placeholder="Percentage"
          value={promoPercentage}
          onChange={(e) => setPromoPercentage(e.target.value)}
        />
        <input
          type="number"
          placeholder="Up To Amount"
          value={promoMaxDiscountAmount}
          onChange={(e) => setPromoMaxDiscountAmount(e.target.value)}
        />
        <input
          type="number"
          placeholder="Minimum Payment Amount"
          value={promoMinPaymentAmount}
          onChange={(e) => setPromoMinPaymentAmount(e.target.value)}
        />
        <input
          type="date"
          placeholder="Until Date"
          value={promoUntilDate}
          onChange={(e) => setPromoUntilDate(e.target.value)}
        />
        <Button variant="danger" onClick={deactivatePromoCode}>
          Deactivate Promo Code
        </Button>
        <Button variant="primary" onClick={savePromoCodeToDatabase}>
          Save Promo Code
        </Button>

        {/* Modal to show promo code saved */}
        <Modal show={showPromoModal} onHide={() => setShowPromoModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{promoIsActive ? 'Promo Code Saved' : 'Promo Code Deactivated'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {promoIsActive ? 'The promo code has been saved successfully!' : 'The promo code has been deactivated successfully!'}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowPromoModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {uploadStatusPromoCode && (
          <Alert variant={uploadStatusPromoCode.type} className="mt-3">
            {uploadStatusPromoCode.message}
          </Alert>
        )}
      </div>
      <div>
        <h3>Manage Car Segments</h3>
        {/* Form to add new car segments */}
        <Form>
          <Form.Group controlId="segmentTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={newSegment.title}
              onChange={(e) => setNewSegment({ ...newSegment, title: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="segmentPeople">
            <Form.Label>People</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter number of people"
              value={newSegment.people}
              onChange={(e) => setNewSegment({ ...newSegment, people: parseInt(e.target.value) })}
            />
          </Form.Group>
          <Form.Group controlId="segmentSuitcases">
            <Form.Label>Suitcases</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter number of suitcases"
              value={newSegment.suitcases}
              onChange={(e) => setNewSegment({ ...newSegment, suitcases: parseInt(e.target.value) })}
            />
          </Form.Group>
          <Form.Group controlId="segmentImageUrl">
            <Form.Label>Image URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter image URL"
              value={newSegment.imageUrl}
              onChange={(e) => setNewSegment({ ...newSegment, imageUrl: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="segmentIsActive">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={newSegment.isActive}
              onChange={(e) => setNewSegment({ ...newSegment, isActive: e.target.checked })}
            />
          </Form.Group>
          <Button variant="primary" onClick={addCarSegment}>
            Add Segment
          </Button>
        </Form>

        {/* List of existing car segments */}
        <div>
          {carSegments
            // Sort segments by title
            .sort((a, b) => a.people - b.people)
            // Map over sorted segments and render each one
            .map((segment) => (
              <li key={segment.id}>
                {segment.title} - {segment.people} people - {segment.isActive ? 'Active' : 'Not Active'}
                {/* Deactivate button */}
                <Button
                  variant="danger"
                  onClick={() => toggleSegmentActivation(segment.id, segment.isActive)}
                  disabled={!segment.isActive}
                >
                  Deactivate
                </Button>
                {/* Activate button */}
                <Button
                  variant="success"
                  onClick={() => toggleSegmentActivation(segment.id, segment.isActive)}
                  disabled={segment.isActive}
                >
                  Activate
                </Button>
              </li>
            ))
          }

        </div>
      </div>
    </div>
  );
};

export default Admin;
